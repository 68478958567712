import * as React from "react"
import {graphql} from 'gatsby';
import { Datenschutzerklärung } from "../components/datenschutzerklärung/Datenschutzerklärung";
const ImpressumPage = () => (
<>
<Datenschutzerklärung/>
</>
)

export default ImpressumPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;